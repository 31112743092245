import React from "react";
import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Layout from "../components/layout";
import MarkdownRenderer from "react-markdown-renderer";

const StaticPage = ({ pageContext }) => {
  const { menu, footerMenu, lang, data, breadcrumbs } = pageContext;

  return (
    <Layout menu={menu} footerMenu={footerMenu} title={data.meta_title}>
      <div className="bg-gradient">
        <Header lang={lang} hrefLang={data.hrefLang} />
        <Breadcrumbs data={breadcrumbs} lang={lang} />
        <div className="main-wrapper-md">
          <div className="static-page-container">
            <header className="section-header red-line">
              <h2>{data.title}</h2>
            </header>
            <MarkdownRenderer markdown={data.content} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StaticPage;
